<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Créditos</v-card-title>
        <DataTableClient :rol="rolUserLogged"></DataTableClient>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableClient from './datatables/DataTableCredit.vue'

export default {
  components: {

    DataTableClient,

  },
  data: () => ({
    rolUserLogged: null,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },
  
}
</script>
