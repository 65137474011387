<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="creditData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingCredits"
      loading-text="Cargando Creditos..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por descripción / monto / etc."
            outlined
            dense
          ></v-text-field>
          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <v-spacer></v-spacer>

          <v-tooltip

            left
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="(rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado') "
                elevation="10"
                small
                fab
                color="primary"
                dark
                class="mx-2"
                v-bind="attrs"
                v-on="on"
                @click="registerCredit()"
              >
                <v-icon>
                  {{ icons.mdiTagPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Registrar Crédito</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon
        size="18"
        class="me-3"
      >
        {{ icons.mdiPencil }}
      </v-icon> -->
        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol=='Encargado')"
              size="18"
              class="me-3"
              v-bind="attrs"
              v-on="on"
              @click="editCredit(item.id)"
            >
              {{ icons.mdiPenPlus }}
            </v-icon>
          </template>
          <span>Modificar Crédito</span>
        </v-tooltip>

        <v-tooltip
          bottom
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="(rol == 'Administrador' || rol == 'Gerente' || rol == 'Encargado')"
              color="error"
              size="18"
              v-bind="attrs"
              class="me-3"
              v-on="on"
              @click="deleteItem(item)"
            >
              {{ icons.mdiDeleteEmpty }}
            </v-icon>
          </template>
          <span>Eliminar Crédito</span>
        </v-tooltip>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip
          :color="getColor(item.state)"
          dark
          small
          style="width:80%; text-align: justify;"
        >
          {{ item.state }}
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDelete"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          v-if="creditDelete"
          class="text-h5"
        >
          ¿Está seguro que desea eliminar el crédito {{ creditDelete.id }} - {{ creditDelete.description }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            @click="deleteItemConfirm"
          >
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiPenPlus, mdiTagPlus, mdiDeleteEmpty,
} from '@mdi/js'

import axios from 'axios'

export default {

  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data: () => ({
    dialogDelete: false,
    icons: {
      mdiPenPlus,
      mdiTagPlus,
      mdiDeleteEmpty,
    },
    headers: [
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'id',
      },

      { text: 'Descripción', value: 'description' },
      { text: 'Cuotas', value: 'quotas' },
      { text: 'Dias Por Cuota', value: 'daysOfQuotas' },
      { text: 'Monto', value: 'amount' },
      { text: 'Monto por Cuota', value: 'amountPerQuota' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    creditData: [],
    buscar: '',
    creditDelete: null,
    loadingCredits: false,
    mensajeSnack: '',

  }),

  // watch: {
  //   dialogDelete(val) {
  //     val || this.closeDelete()
  //   },
  // },
  mounted() {
    this.getCredit()
  },
  methods: {
    deleteItem(item) {
      this.creditDelete = item
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.DeleteCreditConfirm()
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.creditDelete = null
    },
    async DeleteCreditConfirm() {
      this.url = `${this.$store.getters.urlBase}/api/Credit/delete`

      // params.loggedPersonId = parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
          'content-type': 'application/json',
        },
        params: { creditId: parseInt(this.creditDelete.id), personIdLogged: parseInt(this.$store.getters.usuario.user_personid) },
        url: this.url,
      }
      const respuesta = await axios(options).catch(error => {
        if (error.response.data) {
          this.mensajeSnack = `Error: ${error.response.data}`
        } else if (error.message) {
          this.mensajeSnack = `Error: ${error.message}`
        } else {
          this.mensajeSnack = 'Error de Conexion'
        }
        this.$store.dispatch('showSnack', {
          text: this.mensajeSnack,
          color: 'error',
          timeout: 2500,
        })
      })
      if (respuesta.status === 200) {
        this.$store.dispatch('showSnack', {
          text: 'Crédito eliminado!',
          color: 'success',
          timeout: 2500,
        })
      }
      this.creditDelete = null
      this.getCredit()
    },

    registerCredit(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registercredit', params: { id: tipoid } })
    },

    editCredit(tipoid) {
      // this.$router.push('/registeremployee')
      this.$router.push({ name: 'registercredit', params: { id: tipoid } })
    },
    async getCredit() {
      this.url = `${this.$store.getters.urlBase}/api/Credit`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        url: this.url,
      }
      this.loadingCredits = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.creditData = respuesta.data
        this.loadingCredits = false
      } else {
        this.loadingCredits = false
      }
    },

  },
}
</script>
